import React from "react";
import {useHistory}  from "react-router-dom/cjs/react-router-dom.min";

import './Footer.css'
import creditCardsIcon from '../../../utils/assets/footer/credit-cards-icon.png';
import magazinesLoversLogo from '../../../utils/assets/logo/magazines-lovers-logo.png';
import emailIcon from '../../../utils/assets/footer/email-icon.png';
import customerServiceIcon  from '../../../utils/assets/footer/customer-service-icon.png'



const Footer = props => {

    const history = useHistory();
    const handleHomeClick = ( sectionId ) => {
        sessionStorage.setItem('sectionName', sectionId);
        history.push('/');
      };

      const handleGoTo = () =>{
        sessionStorage.setItem('sectionName', 'home');
        window.location.href = '/cancel-service';
      }
    return (
        <footer className="Footer__footer">

            <div className="Footer__main-footer-container">
                <div className="Footer__left-container" >
                    <div className="Footer__left-logo-container">
                        <img src={magazinesLoversLogo} alt="magazinesLoversLogo" className="Footer__logo" />
                    </div>
                   
                    <div className="Footer__left-contact-container" >
                        <img src={customerServiceIcon} alt="customerSeviceIcon" className="Footer__icons" />
                        <p>(888) 474-3416</p>
                    </div>
                    <div className="Footer__left-email-container">
                        <img src={emailIcon} alt="emailIcon" className="Footer__icons" />
                        <p className="testing">
                        help@magazineslovers.com
                        </p>
                    </div>

                    <p className="Footer__business-address">
                        339 8th St N St. Petersburg, FL 33701
                    </p>
                    
                </div>

                <div className="Footer__center-container" >
                    <div className="Footer__center-menu-container">
                        <nav className="Footer__center-nav">
                        <ul>
                               
                               <li><a href="privacy-policy" onClick={() => handleHomeClick('home')} >PRIVACY POLICY</a></li>
                               <li><a href="refund-policy" onClick={() => handleHomeClick('home')}>REFUND-POLICY</a></li>
                               <li><a href="terms-of-service" onClick={() => handleHomeClick('home')}>TERMS OF SERVICE</a></li>
                               <li><a href="cancel-service" onClick={() => handleHomeClick('home')}>CANCEL SERVICE</a></li>
                           </ul>
                        </nav>
                    </div>
                </div>

                <div className="Footer__right-container" >
                    <div className="Footer__right-menu-container">
                        <nav className="Footer__right-nav">
                        <ul >
                                <li><a href="/"  onClick={() => handleHomeClick('home') }>HOME</a></li>
                                <li><a href="/"  onClick={() =>  handleHomeClick('about-us')}>ABOUT US</a></li>
                                <li><a href="/"  onClick={() => handleHomeClick('price')}>PRICE</a></li>
                                <li><a href="/"  onClick={() => handleHomeClick('contact-us')}>CONTACT</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="MainContainer__right-separator"></div>
            
            <div className="Footer__main-legal-description-container">
                <div className="Footer__legal-description-container">
                    <p className="text-left">
                        Dear Member, you will receive a text or email notification 48 hours prior to your 
                        monthly bill date. If you do not wish to continue with your enrollment, you may call our 
                        Customer Service Department (888) 474-3416 or click <a href="/cancel-service" onClick={handleGoTo}>here</a> to cancel and submit your request.
                    </p>
                </div>
                <div className="Footer__right-and-cards-icon-container">
                    <div className="Footer__copy-right-container">
                        <p>
                            <strong> Magazines Lovers © 2025 All Rights Reserved</strong>
                        </p>
                    </div>
                    <div className="Footer__card-icon-container">
                        <div className="Footer__credit-card-icons">
                            <img src={creditCardsIcon} alt="creditCardIcon" />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;